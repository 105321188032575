import ContactForm from "./ContactForm";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

const Muofit = () => {
  const mTheme = useTheme();
  const isMobile = useMediaQuery(mTheme.breakpoints.down("md"));
  return (
    <div className="muofit">
      <div className="muofit__outlineText">ARE YOU MU OF IT?</div>
      <div className="muofit__normalText">Join the early muvement!</div>
      <ContactForm isMobile={isMobile} />
    </div>
  );
};

export default Muofit;

