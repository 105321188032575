import React, { useState } from 'react';

const ContactForm = ({ isMobile }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const validate = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = 'Name is required';

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }

        if (!formData.subject.trim()) newErrors.subject = 'Subject is required';
        if (!formData.message.trim()) newErrors.message = 'Message is required';

        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: null
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = validate();

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await fetch('https://backend-util.plottwiststudios.co/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: formData.name,
                    email: formData.email,
                    subject: formData.subject,
                    message: formData.message
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to send email. Please try again later.');
            }

            setSubmitted(true);
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });

            setTimeout(() => {
                setSubmitted(false);
            }, 5000);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`mx-auto mt-12 relative ${!isMobile ? 'w-4/5' : 'w-full'}`}>
            <div
                className={`relative overflow-hidden ${isMobile ? 'p-8 pb-0 rounded-lg' : 'p-16 pt-16 pb-8 rounded-xl'} bg-white shadow-lg`}
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0 7px 29px 0'
                }}
            >
                {/* Before pseudo element */}
                {!isMobile && (
                    <div
                        className="absolute w-64 h-64 rounded-full -left-32 top-9 -rotate-90"
                        style={{
                            background: 'linear-gradient(120deg, rgba(255, 255, 255, 0.01) 24.01%, rgba(25, 228, 204, 0.4) 71.35%)',
                            zIndex: 1
                        }}
                    ></div>
                )}

                {/* After pseudo element */}
                {!isMobile && (
                    <div
                        className="absolute w-64 h-64 rounded-full -right-32 top-5 rotate-90"
                        style={{
                            background: 'linear-gradient(120deg, rgba(255, 255, 255, 0.01) 24.01%, rgba(25, 228, 204, 0.4) 71.35%)',
                            zIndex: 1
                        }}
                    ></div>
                )}

                {/* Content with higher z-index */}
                <div className="relative z-10">
                    <h2 className="text-2xl font-bold mb-6 text-gray-800">Contact Us</h2>

                    {submitted && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                            Thank you for your message! We'll get back to you soon.
                        </div>
                    )}

                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {error}
                        </div>
                    )}

                    {loading ? (
                        <div className="loading text-center pb-8 text-black">
                            <svg className="animate-spin h-8 w-8 mx-auto mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Sending your message...
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="relative z-10">
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 ${errors.name ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    placeholder="Your name"
                                />
                                {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 ${errors.email ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    placeholder="your.email@example.com"
                                />
                                {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="subject" className="block text-gray-700 text-sm font-medium mb-2">
                                    Subject
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 ${errors.subject ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    placeholder="What is this regarding?"
                                />
                                {errors.subject && <p className="mt-1 text-sm text-red-500">{errors.subject}</p>}
                            </div>

                            <div className="mb-6">
                                <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="4"
                                    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 ${errors.message ? 'border-red-500' : 'border-gray-300'
                                        }`}
                                    placeholder="Your message here..."
                                ></textarea>
                                {errors.message && <p className="mt-1 text-sm text-red-500">{errors.message}</p>}
                            </div>

                            <button
                                type="submit"
                                className="w-full bg-gradient-to-r from-teal-400 to-teal-500 text-white font-medium py-2 px-4 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 hover:from-teal-500 hover:to-teal-600 disabled:opacity-50"
                                disabled={loading}
                            >
                                Send Message
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
